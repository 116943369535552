export default {
  namespaced: true,

  state() {
    return {
      showMenu: true,
      isWizardDisabled: false,
      isMenuCollapsed: false,
    }
  },

  getters: {
    showMenu(state) {
      return state.showMenu
    },

    isWizardDisabled(state) {
      return state.isWizardDisabled
    },

    isMenuCollapsed(state) {
      return state.isMenuCollapsed
    }
  },

  actions: {
    async showMenu({ commit }, show) {
      commit('SHOW_MENU', show)
    },
    async setWizardDisabled({ commit }, isDisabled) {
      commit('SET_WIZARD_DISABLED', isDisabled)
    },
    async toggleMenu({ commit }) {
      commit('TOGGLE_MENU')
    },
    async setMenuCollapsed({ commit }, collapsed) {
      commit('SET_MENU_COLLAPSED', collapsed)
    }
  },

  mutations: {
    SHOW_MENU(state, show) {
      state.showMenu = show
    },
    SET_WIZARD_DISABLED(state, isDisabled) {
      state.isWizardDisabled = isDisabled
    },
    TOGGLE_MENU(state) {
      state.isMenuCollapsed = !state.isMenuCollapsed
    },
    SET_MENU_COLLAPSED(state, collapsed) {
      state.isMenuCollapsed = collapsed
    }
  }
}